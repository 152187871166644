import { ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function parseError(errors, d) {
  if (errors.length > 0) {
    return errors[0].message;
  }
  return d;
}

export function redirectApp() {
  const sourceUrl = new URL(window.location.href).origin;
  const sourceAppName = "yapikredi";

  window.open(
    `${import.meta.env.VITE_FE_URL}/login/jwt?token=${localStorage
      .getItem("JWTToken")
      .replace(
        /^Bearer\s+/i,
        "",
      )}&sourceUrl=${sourceUrl}&sourceAppName=${sourceAppName}`,
    "_self",
  );
}

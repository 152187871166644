import * as React from "react";

import { cn } from "@/lib/utils";

const Input = React.forwardRef<HTMLInputElement>(
  (
    {
      className,
      type,
      error,
      ...props
    }: {
      className?: string;
      type?: "text" | "number" | "email" | "password";
      error?: boolean;
    },
    ref,
  ) => {
    return (
      <input
        type={type}
        className={cn(
          "flex w-full rounded-md border border-[#CDD5E0] bg-white px-4 py-[14px]  text-[#111729] ring-offset-[#C4DAFB] file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-[#677389a8] focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-[#C4DAFB] focus-visible:ring-offset-2  disabled:bg-[#F2F5F9]",
          error && "border-[#D92D20] ring-[#D92D20] ring-offset-[#fcf4f4]",
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Input.displayName = "Input";

export { Input };

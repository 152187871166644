import { Slot } from "@radix-ui/react-slot";

import * as React from "react";

import { type VariantProps, cva } from "class-variance-authority";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center gap-2 rounded-[8px] transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background border border-transparent shadow-sm",
  {
    variants: {
      variant: {
        default: "bg-[#0049E6] text-white hover:bg-[#263FA8]",
        secondaryGray:
          "bg-white border-[#CDD5E0] hover:bg-[#F9FAFC] text-[#364153]",
        secondaryColor:
          "bg-[#F1F6FE] text-[#2A4ED0] text-[#2A4ED0] hover:bg-[#E5F0FF] hover:text-[#2A4ED0]",
        tertiaryGray:
          "bg-white text-[#364153] shadow-none hover:bg-[#F2F5F9] hover:text-[#364153]",
        ghost: "bg-transparent shadow-none",
      },
      size: {
        sm: "h-9 px-3",
        default: "h-10 py-2 px-4",
        lg: "h-11 px-8",
        full: "w-full h-11 px-8",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };

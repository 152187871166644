import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import React from "react";

import { Input } from "@/components/ui/Input";

const PriceInputPrice = ({
  value,
  onChange,
  placeholder,
  onChangeUnit,
  valueUnit,
  ref,
}) => {
  const formatNumberWithCommas = (number) => {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };
  const formattedValue = formatNumberWithCommas(value);

  const handleChange = (event) => {
    const enteredValue = event.target.value.replace(/\./g, "").trim(); // Remove existing dots
    const numericValue = parseInt(enteredValue, 10);

    if (!isNaN(numericValue)) {
      onChange(numericValue);
    }

    if (enteredValue === "") {
      onChange(null);
    } else if (!isNaN(numericValue)) {
      onChange(numericValue);
    }
  };

  return (
    <div className="flex w-full h-full items-center">
      <CurrencySelect valueUnit={valueUnit} onChangeUnit={onChangeUnit} />
      <Input
        ref={ref}
        type="number"
        value={formattedValue}
        onChange={handleChange}
        placeholder={placeholder}
        className="border-l-0 rounded-l-none"
      />
    </div>
  );
};

export default PriceInputPrice;

const CurrencySelect = ({ valueUnit, onChangeUnit }) => {
  const options = [
    { value: "TRY", label: "₺" },
    { value: "USD", label: "$" },
    { value: "EUR", label: "€" },
    { value: "GBP", label: "£" },
  ];

  return (
    <DropdownMenu.Root value={valueUnit} onChange={onChangeUnit}>
      <DropdownMenu.Trigger className="" asChild>
        <button className="h-full" aria-label="Para birimi seçin">
          <div className="flex items-center justify-center rounded-md py-[14px] rounded-r-none  border border-gray-300 border-r-0 px-4">
            <span className="text-gray-700">
              {options.find((option) => option.value === valueUnit)?.label}
            </span>
          </div>
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        side="top"
        sideOffset={2}
        className="bg-white p-2 max-w-20 shadow border rounded-md"
      >
        <DropdownMenu.Group>
          {options.map((option) => (
            <DropdownMenu.Item
              className="flex items-center p-2 bg-white rounded-md hover:bg-gray-100 cursor-pointer"
              key={option.value}
              value={option.value}
              onSelect={() => {
                onChangeUnit(option.value);
              }}
            >
              <span className="text-gray-700">{option.label}</span>
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Group>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

import React from "react";
import { useState } from "react";

import { Button } from "@/components/ui/Button";

import EgarantiLogo from "@/assets/egaranti-logo.png";
import Banner1 from "@/assets/onboard1.png";
import Banner2 from "@/assets/onboard2.png";

//import Banner3 from "@/assets/onboard3.png";
//import Banner4 from "@/assets/onboard4.png";

const Onboarding = ({ onboardingCompleted }) => {
  const [step, setStep] = useState(0);
  const [steps] = useState([
    {
      image: Banner1,
      subTitle: "Garanti belgesi aramaktan yoruldunuz mu? 😮‍💨",
      title: "Tüm Ürünleriniz Tek Yerde 🙌🏻",
      description:
        "egaranti, ürünlerin garanti belgelerini görüntülemenizi ve garanti süreçlerinizi yönetmenizi sağlar.",
    },
    {
      image: Banner2,
      subTitle: "Tüm garanti belgeleriniz artık dijitalde! 🙌🏻",
      title: "Ürünlerinizi Ekleyin ✔️",
      description:
        "Tüm ürünlerinizin garanti belgelerini 1 dakikadan kısa sürede ekleyin. İhtiyaç anında hemen ulaşın!",
    },
  ]);

  const onNextStep = () => {
    if (step === steps.length - 1) {
      onboardingCompleted();
    } else {
      setStep(step + 1);
    }
  };

  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  return (
    <div className="flex flex-col gap-8 p-6">
      <img src={EgarantiLogo} alt="Egara Logo" className="h-8 mx-auto mb-4" />
      <div className="flex flex-col gap-4 p-6 border rounded-lg">
        <h3
          className="text-[#0037AD] text-xl text-center font-semibold leading-6 px-2"
          variants={variants}
          initial="hidden"
          animate="visible"
        >
          {steps[step].subTitle}
        </h3>
        <img
          loading="lazy"
          src={steps[step].image}
          className="mx-auto w-80 select-none"
        />
      </div>
      <div className="flex flex-col items-center justify-center gap-2">
        <h1 className="text-xl font-semibold text-[#16182C] text-center">
          {steps[step].title}
        </h1>
        <h3 className="text-[#4A5567] text-center mb-4 text-sm">
          {steps[step].description}
        </h3>
        <Button onClick={onNextStep} className="mt-6 w-full">
          Devam Et
        </Button>
      </div>
    </div>
  );
};

export default Onboarding;

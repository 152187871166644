import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { Toaster } from "@/components/ui/toaster";

import routes from "@/routes";

const App = () => {
  const router = createBrowserRouter(routes, {});

  return (
    <>
      <Toaster />
      <RouterProvider router={router} />
    </>
  );
};

export default App;

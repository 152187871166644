import { zodResolver } from "@hookform/resolvers/zod";

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import Onboarding from "../onboarding";

import { Button } from "@/components/ui/Button";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/Form";
import { Input } from "@/components/ui/Input";
import { Label } from "@/components/ui/Label";
import { useToast } from "@/components/ui/use-toast";

import EgarantiLogo from "@/assets/egaranti-logo.png";

import * as z from "zod";

import { selectEmail, selectPhoneNo } from "@/lib/PassportYapiKrediMobile";
import { apiYapikredi } from "@/lib/axios";
import { redirectApp } from "@/lib/utils";

const Register = () => {
  const navigate = useNavigate();
  const [onboardingCompleted, setOnboardingCompleted] = useState(false);
  const { toast } = useToast();
  const [loading, setLoading] = React.useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const sessionToken = urlParams.get("sessionToken");

  const formSchema = z.object({
    phone: z
      .string("Lütfen telefon numaranızı seçiniz")
      .min(10, "Lütfen geçerli bir telefon numarası giriniz"),
    email: z
      .string("Lütfen geçerli bir email adresi giriniz")
      .email("Lütfen geçerli bir email adresi giriniz"),
    name: z
      .string("Lütfen adınızı ve soyadınızı giriniz")
      .min(3, "Lütfen adınızı ve soyadınızı giriniz")
      .max(50),
  });

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      phone: "",
      email: "",
      name: "",
    },
  });

  const getUserInfo = () => {
    setLoading(true);

    // if there isn't a sessionToken, check local storage for it and add it to the url params
    let sessionTokenX = "";

    if (!sessionToken) {
      sessionTokenX = localStorage.getItem("sessionToken");
    } else {
      sessionTokenX = sessionToken;
    }

    apiYapikredi
      .get(`/session-info?sessionToken=${sessionTokenX}`)
      .then((response) => {
        form.setValue(
          "name",
          response.data.firstName + " " + response.data.lastName,
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (urlParams.get("sessionToken")) {
      localStorage.setItem("sessionToken", urlParams.get("sessionToken"));
    }

    const onboardingStatus = localStorage.getItem("onboarding");
    if (onboardingStatus === "true") {
      setOnboardingCompleted(true);
    }

    getUserInfo();

    if (
      localStorage.getItem("expiredAtTime") &&
      new Date(localStorage.getItem("expiredAtTime")) < new Date().getTime()
    ) {
      localStorage.removeItem("JWTToken");
    } else {
      if (!localStorage.getItem("JWTToken")) {
      } else {
        redirectApp();
      }
    }

    window.emailSelected = () => {
      onSelectEmailCompleted();
    };

    window.phoneNoSelected = () => {
      onSelectPhoneCompleted();
    };
  }, []);

  function onSelectEmailCompleted() {
    // bu fonksiyon email seçildiğinde çalışacak
    // https://replica-yapikredi.egaranti.dev/selected-email?sessionToken=PSPT607F7EAAA46349C9A613B4B6E12AB74D
    setLoading(true);
    apiYapikredi
      .get(
        `/selected-email?sessionToken=${localStorage.getItem("sessionToken")}`,
      )
      .then((response) => {
        form.setValue("email", response.data.email);
      })
      .catch((error) => {
        form.setValue("email", "HATA");
      })
      .finally(() => setLoading(false));
  }

  function onSelectPhoneCompleted() {
    // bu fonksiyon telefon numarası seçildiğinde çalışacak
    setLoading(true);
    apiYapikredi
      .get(
        `/selected-phone?sessionToken=${localStorage.getItem("sessionToken")}`,
      )
      .then((response) => {
        form.setValue("phone", response.data.phone);
        form.setValue("registered", response.data.registered);
        localStorage.removeItem("JWTToken");
        localStorage.removeItem("expiredAtTime");
        localStorage.setItem("JWTToken", response.data.jwtToken);
        localStorage.setItem("expiredAtTime", response.data.expiredAt);
        if (response.data.registered && response.data.phone) {
          navigate("/onboarding");
        }
      })
      .catch((error) => {
        form.setError("phone", "HATA");
      })
      .finally(() => setLoading(false));
  }

  const formErrors = form.formState?.errors;
  useEffect(() => {
    if (formErrors) {
      const key = Object.keys(formErrors)[0];
      const error = formErrors[key];
      if (error?.message) {
        toast({
          title: error.message,
          variant: "warning",
        });
      }
    }
  }, [formErrors]);

  const onSubmit = (data) => {
    setLoading(true);
    apiYapikredi
      .post("/auth/register", {
        sessionToken: localStorage.getItem("sessionToken"),
      })
      .then((response) => {
        localStorage.removeItem("JWTToken");
        localStorage.removeItem("expiredAtTime");
        localStorage.setItem("JWTToken", response.data.jwtToken);
        localStorage.setItem("expiredAtTime", response.data.expiredAt);
        navigate("/onboarding");
      })
      .catch((err) => {
        toast({
          title: err.response?.data?.errors[0]?.message,
          variant: "error",
        });
      })
      .finally(() => setLoading(false));
  };

  if (loading)
    return (
      <div className="flex items-center justify-center h-screen">
        <img
          src={EgarantiLogo}
          alt="Egaranti Logo"
          className="h-8 animate-pulse delay-0"
        />
      </div>
    );

  const handleOnboardingCompleted = () => {
    localStorage.setItem("onboarding", "true");
    setOnboardingCompleted(true);
  };

  if (!onboardingCompleted) {
    return <Onboarding onboardingCompleted={handleOnboardingCompleted} />;
  }

  return (
    <div className="flex flex-col">
      <img
        onDoubleClick={() => localStorage.clear()}
        src={EgarantiLogo}
        alt="Egaranti Logo"
        className="h-8 mx-auto mb-12"
      />
      <div className="flex flex-col gap-3 mb-8">
        <h1 className="text-xl font-semibold text-[#101828]">
          Hesabınızla devam edin
        </h1>
        <h3 className="text-[#677389]">
          Hesap bilgilerinizi girerek veya seçerek devam edebilirsiniz.
        </h3>
      </div>
      <Form {...form}>
        <form className="space-y-5">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <Label>Ad Soyad</Label>
                <FormControl>
                  <Input {...field} readOnly />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
              <FormItem>
                <Label>Telefon Numarası</Label>
                <FormControl>
                  <Input
                    onClick={() => selectPhoneNo()}
                    readOnly
                    aria-label="Telefon numarası seç"
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          {form.watch("registered") === false && (
            <>
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <Label>Email</Label>
                    <FormControl>
                      <Input
                        onClick={() => selectEmail()}
                        readOnly
                        aria-label="Email seç"
                        {...field}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </>
          )}
          <Button onClick={form.handleSubmit(onSubmit)} className="w-full">
            Devam Et
          </Button>
        </form>
      </Form>
    </div>
  );
};

export default Register;

import Packages from "./components/packages";
import ProductRegister from "./components/productRegister";
import RedirectOnboarding from "./components/redirectOnboarding";
import HomePage from "./pages/HomePage";

const routes = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/product-register",
    element: <ProductRegister />,
  },
  {
    path: "/onboarding",
    element: <RedirectOnboarding />,
  },
  {
    path: "/product-register/step-2",
    element: <Packages />,
  },
];

export default routes;

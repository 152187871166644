import React from "react";

import { Button } from "@/components/ui/Button";

import EgarantiLogo from "@/assets/egaranti-logo.png";

const Packages = () => {
  return (
    <div className="flex flex-col p-6 bg-[#F9FAFC] min-h-screen">
      <img
        src={EgarantiLogo}
        alt="Egaranti Logo"
        className="h-8 mx-auto mb-12"
      />
      <div className="flex flex-col gap-3 mb-8">
        <h1 className="text-xl font-semibold text-[#101828]">
          Kapsamınızı gözden geçirin
        </h1>
        <h3 className="text-[#677389]">
          egaranti size esnek ve kapsamlı koruma paketleri sunar. Şartlar ve
          Koşulları inceleyin.
        </h3>
      </div>
      <div className="bg-white rounded-lg p-6 flex flex-col gap-3">
        <h4 className="text-xl font-medium text-[#364153] text-center">
          Bilgisayar Koruma Paketi
        </h4>
        <p className="text-[#677389] text-xs text-center font-medium">
          Esnek ve güvenilir. Yılda bir ödeme yapın.
        </p>
        <h2 className="text-3xl font-semibold text-[#101828] text-center">
          3.599,99₺
        </h2>
        <ul className="flex flex-col gap-4 mt-6">
          <li className="flex items-center gap-3 text-sm font-medium text-[#364153]">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.14626 1.77539L4.2352 5.28771L7.93761 7.36088L4.14626 1.77539ZM13.3187 2.56971L9.89318 3.83428L10.6293 9.84375L13.3187 2.56971ZM17.3694 5.33827L12.6617 7.44539L15.0369 11.6554L15.2357 12.0092L14.849 12.1312L11.964 13.0375L13.9833 14.7689L14.8183 15.4852L13.7274 15.3446L4.78993 14.191L3.54519 14.0306L4.70865 13.5604L9.18007 11.7553L6.09961 10.3721L0.694336 12.7936V17.387H17.3694V5.33827H17.3694ZM4.79113 7.1126L1.3975 7.90907L10.0756 10.4524L4.79116 7.11253L4.79113 7.1126Z"
                fill="#0049E6"
              />
            </svg>
            Kaza Sonucu Hasar
          </li>
          <li className="flex items-center gap-3 text-sm font-medium text-[#364153]">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7235 5.72353C11.4264 5.42652 11.2779 5.27801 11.2223 5.10676C11.1734 4.95613 11.1734 4.79387 11.2223 4.64324C11.2779 4.47199 11.4264 4.32348 11.7235 4.02647L13.8522 1.89769C13.2873 1.64222 12.6602 1.5 11.9999 1.5C9.51464 1.5 7.49993 3.51472 7.49993 6C7.49993 6.36828 7.54417 6.72623 7.62762 7.06882C7.71699 7.43568 7.76168 7.61911 7.75374 7.735C7.74544 7.85632 7.72735 7.92087 7.6714 8.02884C7.61796 8.13197 7.51557 8.23436 7.31079 8.43914L2.62493 13.125C2.00361 13.7463 2.00361 14.7537 2.62493 15.375C3.24625 15.9963 4.25361 15.9963 4.87493 15.375L9.56079 10.6891C9.76557 10.4844 9.86796 10.382 9.97109 10.3285C10.0791 10.2726 10.1436 10.2545 10.2649 10.2462C10.3808 10.2382 10.5642 10.2829 10.9311 10.3723C11.2737 10.4558 11.6316 10.5 11.9999 10.5C14.4852 10.5 16.4999 8.48528 16.4999 6C16.4999 5.33969 16.3577 4.71261 16.1022 4.14769L13.9735 6.27647C13.6764 6.57348 13.5279 6.72199 13.3567 6.77763C13.2061 6.82658 13.0438 6.82658 12.8932 6.77763C12.7219 6.72199 12.5734 6.57348 12.2764 6.27647L11.7235 5.72353Z"
                stroke="#0049E6"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Mekanik Arıza
          </li>
          <li className="flex items-center gap-3 text-sm font-medium text-[#364153]">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 10.5C15 13.8137 12.3137 16.5 9 16.5C5.68629 16.5 3 13.8137 3 10.5C3 9.70432 3.15488 8.94482 3.43614 8.25C4.32622 6.05113 9 1.5 9 1.5C9 1.5 13.6738 6.05113 14.5639 8.25C14.8451 8.94482 15 9.70432 15 10.5Z"
                stroke="#0049E6"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Sıvı Teması
          </li>
          <li className="flex items-center gap-3 text-sm font-medium text-[#364153]">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.35516 13.8978C4.36844 13.8754 4.38205 13.8532 4.39598 13.831C5.41185 12.2161 5.99993 10.3063 5.99993 8.25C5.99993 6.59315 7.34308 5.25 8.99993 5.25C10.6568 5.25 11.9999 6.59315 11.9999 8.25C11.9999 9.01278 11.948 9.76394 11.8475 10.5M10.2594 15.6327C10.7182 14.717 11.0943 13.7527 11.378 12.75M14.2572 13.599C14.741 11.8993 15 10.1049 15 8.25C15 4.93629 12.3137 2.25 9 2.25C7.90714 2.25 6.88252 2.54218 6 3.05269M2.25 11.5231C2.73049 10.534 3 9.42348 3 8.25C3 7.15714 3.29218 6.13252 3.80269 5.25M8.9999 8.25C8.9999 10.8879 8.24332 13.3491 6.9353 15.4284"
                stroke="#0049E6"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Hırsızlık
          </li>
        </ul>
        <div className="flex flex-col gap-4 mt-6">
          <Button className="w-full">Planı Seç</Button>
          <Button variant="secondaryGray" className="w-full">
            Planı Seçmeden İlerle
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Packages;

import React, { useEffect } from "react";
import { useState } from "react";
//import Banner4 from "@/assets/onboard4.png";
import { useNavigate } from "react-router-dom";

import { Button } from "@/components/ui/Button";

import EgarantiLogo from "@/assets/egaranti-logo.png";
import Banner3 from "@/assets/onboard3.png";

import { redirectApp } from "@/lib/utils";

const RedirectOnboarding = ({}) => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [steps] = useState([
    {
      image: Banner3,
      title: "egaranti’yi Kullanmaya Şimdi Başlayın ✨",
      description:
        "Evinizdeki ürünleri ekleyerek ya da anasayfayı ziyaret edip fırsatlarımıza göz atarak kullanmaya başlayabilirsiniz.",
    },
  ]);

  return (
    <div className="flex flex-col gap-8 p-6">
      <img src={EgarantiLogo} alt="Egara Logo" className="h-8 mx-auto mb-4" />
      <div className="flex flex-col items-center justify-center gap-2">
        <h1 className="text-xl font-semibold text-[#16182C] text-center">
          {steps[step].title}
        </h1>
        <h3 className="text-[#4A5567] text-center mb-4 text-sm">
          {steps[step].description}
        </h3>
        <div className="flex flex-col gap-4 p-6 border rounded-lg">
          <img
            loading="lazy"
            src={steps[step].image}
            className="mx-auto w-80 select-none"
          />
        </div>
        <Button
          onClick={() => navigate("/product-register")}
          className="mt-6 w-full"
        >
          Ürün Ekle
        </Button>
        <Button
          variant="secondaryColor"
          onClick={() => {
            redirectApp();
          }}
          className="mt-2 w-full"
        >
          Anasayfaya Git
        </Button>
      </div>
    </div>
  );
};

export default RedirectOnboarding;

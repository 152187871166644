//import i18n from "../i18n";
import axios from "axios";

const apiYapikredi = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_YAPIKREDI,
  headers: {
    //Authorization: `${localStorage.getItem("JWTToken")}`,
  },
});

const api = axios.create({
  //baseURL: "https://replica-gw-individual.egaranti.dev",
  baseURL: import.meta.env.VITE_BACKEND,
  headers: {
    //Authorization: `${localStorage.getItem("JWTToken")}`,
  },
});

apiYapikredi.interceptors.request.use((config) => {
  config.headers["Authorization"] = `${localStorage.getItem("JWTToken")}`;
  return config;
});

api.interceptors.request.use((config) => {
  config.headers["Authorization"] = `${localStorage.getItem("JWTToken")}`;
  return config;
});

export { apiYapikredi, api };

//import { parseError } from "@lib/utils";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Step1 from "./Step1";
import Step2 from "./Step2";

import { Button } from "@/components/ui/Button";
import { useToast } from "@/components/ui/use-toast";

import EgarantiLogo from "@/assets/egaranti-logo.png";

import { AnimatePresence } from "framer-motion";
import moment from "moment";
import * as yup from "yup";

import { api } from "@/lib/axios";
import { redirectApp } from "@/lib/utils";

const ProductRegister = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [loading, setLoading] = React.useState(false);
  const [step, setStep] = React.useState(0);
  const [formState, setFormState] = React.useState({
    priceUnit: "TRY",
    productPrice: "",
    billFile: null,
    purchaseDate: moment(new Date()).format("DDMMYYYY"),
    brand: "",
    selectedProductValue: "",
  });

  const formStepsProps = {
    steps: [
      {
        title: "Evinizdeki Ürünlerinizi Ekleyin",
        description: "Ürünlerinizi eklemek için biraz bilgiye ihtiyacımız var.",
        buttons: [
          {
            text: "Devam Et",
            onClick: () => {
              handleNextStep();
            },
          },
        ],
        content: (
          <Step1 key={1} formState={formState} setFormState={setFormState} />
        ),
      },
      // {
      //   title: t("productsPage.step2Title"),
      //   description: t("productsPage.step2Desc"),
      //   buttons: [
      //     {
      //       text: "Devam Et",
      //       onClick: () => {
      //         handleSubmitProduct();
      //       },
      //     },
      //   ],
      //   content: <Step2 key={2} />,
      // },
      {
        content: (
          <div className="flex flex-col gap-6 items-center">
            <svg
              width="65"
              height="64"
              viewBox="0 0 65 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="0.5" width="64" height="64" rx="32" fill="#D1FADF" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M46.0904 19.7067L26.9971 38.1334L21.9304 32.72C20.9971 31.84 19.5304 31.7867 18.4638 32.5334C17.4238 33.3067 17.1304 34.6667 17.7704 35.76L23.7704 45.52C24.3571 46.4267 25.3704 46.9867 26.5171 46.9867C27.6104 46.9867 28.6504 46.4267 29.2371 45.52C30.1971 44.2667 48.5171 22.4267 48.5171 22.4267C50.9171 19.9734 48.0104 17.8134 46.0904 19.68V19.7067Z"
                fill="#12B76A"
              />
            </svg>
            <h2 className="text-2xl font-semibold text-[#101828] text-center mt-4">
              Harika! Başarıyla ürün eklediniz.
            </h2>
            <p className="text-[#677389] text-center text-lg">
              Ürün eklediğiniz için teşekkürler.
            </p>
            <Button
              className="w-full"
              onClick={() => {
                redirectApp();
              }}
            >
              Ana Sayfaya Git
            </Button>
          </div>
        ),
      },
    ],
  };

  const handleNextStep = async () => {
    const verifiedPorductSchema = yup.object().shape({
      billFile: yup
        .mixed()
        .required("Fatura yüklemek zorunludur.")
        .test("fileSize", "Dosya boyutu 10MB'dan büyük olamaz.", (value) => {
          return value && value.size <= 10000000;
        }),
      selectedProduct: yup.object().required("Ürün seçimi zorunludur."),
      productPrice: yup.string().required("Ürün fiyatı zorunludur."),
    });

    const unverifiedProductSchema = yup.object().shape({
      selectedProductValue: yup.string().required("Ürün adı zorunludur."),
      brand: yup.string().required("Marka zorunludur."),
      unverifiedProductCategoryId: yup
        .string()
        .required("Katagori seçimi zorunludur."),
      purchaseDate: yup.date().required("Satın alma tarihi zorunludur."),
      productPrice: yup.string().required("Ürün fiyatı zorunludur."),
      billFile: yup
        .mixed()
        .required("Fatura yüklemek zorunludur.")
        .test("fileSize", "Dosya boyutu 10MB'dan büyük olamaz.", (value) => {
          return value && value.size <= 10000000;
        }),
    });

    const schema = formState.selectedProduct
      ? verifiedPorductSchema
      : unverifiedProductSchema;

    try {
      await schema.validate(formState, { abortEarly: false });
      handleSubmitProduct();
    } catch (err) {
      const errorText = err.errors?.map((_) => <p>• {_}</p>);

      toast({
        description: errorText,
        variant: "error",
      });
    }
  };

  const handleSubmitProduct = async () => {
    // detecct isRegistered or not
    setLoading(true);
    if (formState.selectedProduct) {
      try {
        const formData = new FormData();
        formData.append("file", formState.billFile);

        const queryParams = {
          name: formState.selectedProduct.name,
          brand: formState.selectedProduct.brand,
          unverifiedProductCategoryId:
            formState.selectedProduct.unverifiedProductCategoryId,
          warrantyStartDate: moment(formState.purchaseDate, "DDMMYYYY").format(
            "YYYY-MM-DD",
          ),
          warrantYear: 2,
          productPrice: formState.productPrice,
          priceUnit: formState.priceUnit,
        };

        api({
          method: "post",
          url: `${import.meta.env.VITE_BACKEND}/unverified-products`,
          params: queryParams,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data", // FormData kullanıyorsak bu başlığı eklemeliyiz
          },
        }).then((res) => {
          setLoading(false);
          setStep(1);
          toast({
            description: "Ürün başarıyla eklendi.",
            variant: "success",
          });
        });
      } catch (err) {
        //const errorText = parseError(err.response.data.errors);
        const errorText = err.errors?.map((_) => <p>• {_}</p>);
        toast({
          description: errorText,
          variant: "error",
        });
      }
    } else {
      try {
        const formData = new FormData();
        formData.append("file", formState.billFile);

        const queryParams = {
          name: formState.selectedProductValue,
          brand: formState.brand,
          unverifiedProductCategoryId: formState.unverifiedProductCategoryId,
          warrantyStartDate: moment(formState.purchaseDate, "DDMMYYYY").format(
            "YYYY-MM-DD",
          ),
          warrantYear: 2,
          productPrice: formState.productPrice,
        };
        setLoading(true);
        api({
          method: "post",
          url: `${import.meta.env.VITE_BACKEND}/unverified-products`,
          params: queryParams,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data", // FormData kullanıyorsak bu başlığı eklemeliyiz
          },
        })
          .then((res) => {
            setLoading(false);
            setStep(1);
            toast({
              description: "Ürün başarıyla eklendi.",
              variant: "success",
            });
          })
          .catch((err) => {
            setLoading(false);
            const errorText = parseError(err.response.data.errors);

            toast({
              description: errorText,
              variant: "error",
            });
          });
      } catch (err) {
        setLoading(false);
        const errorText = parseError(err.response.data.errors);
        toast({
          description: errorText,
          variant: "error",
        });
      }
    }
  };

  return (
    <div className="flex flex-col p-6">
      <div className="relative flex items-center justify-center">
        <button
          onClick={() => navigate("/onboarding")}
          aria-label="Geri dön"
          className="absolute left-0 top-0 h-8"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.8337 9.99996H4.16699M4.16699 9.99996L10.0003 15.8333M4.16699 9.99996L10.0003 4.16663"
              stroke="#364153"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        <img src={EgarantiLogo} alt="Egaranti Logo" className="h-8 mb-12" />
      </div>
      <div className="flex w-full flex-col">
        <div className="gap-3 flex flex-col w-full mb-5">
          <h2 className="text-[#101828] font-semibold text-lg">
            {formStepsProps.steps[step].title}
          </h2>
          <p className="text-[#677389] min-h-[50px]">
            {formStepsProps.steps[step].description}
          </p>
        </div>
        {/* framer animation when change step  */}
        <AnimatePresence mode="wait">
          {formStepsProps.steps[step].content}
        </AnimatePresence>
        <div className="flex justify-end gap-3 mt-5">
          {formStepsProps.steps[step].buttons?.map((button, index) => (
            <Button disabled={loading} key={index} onClick={button.onClick}>
              {button.text}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductRegister;

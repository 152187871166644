/* eslint-disable react/prop-types */
import {
  FileArea,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@egaranti/components";

import { useEffect, useRef, useState } from "react";

import PriceInputBox from "@/components/PriceInputBox";
import { Input } from "@/components/ui/Input";
import { Label } from "@/components/ui/Label";

import { motion } from "framer-motion";
import { withMask } from "use-mask-input";

import { api } from "@/lib/axios";

const Step1 = ({ formState, setFormState, key }) => {
  const ref = useRef();
  const [isShowProductList, setIsShowProductList] = useState(false);
  const [productListFromApi, setProductListFromApi] = useState([]);
  const [unverifiedProductCategories, setUnverifiedProductCategories] =
    useState([]);
  const debounceTimeout = useRef(null);

  const debouncedFunction = (event) => {
    clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(() => {
      setIsShowProductList(true);
      console.log("debounced");
      api
        .get(import.meta.env.VITE_BACKEND + "/unverified-product-templates", {
          headers: {},
          params: {
            query: event.target.value,
            page: 1,
            size: 10,
          },
        })
        .then((res) => {
          setProductListFromApi(res.data.content);

          if (res.data.content.length === 0) {
            setFormState((prevState) => {
              return {
                ...prevState,
                selectedProduct: null,
              };
            });
          }
        });
    }, 250);
  };

  const getUnverifiedProductCategories = () => {
    api
      .get(import.meta.env.VITE_BACKEND + "/unverified-product-categories", {
        headers: {},
      })
      .then((res) => {
        setUnverifiedProductCategories(res.data);
      });
  };

  const handleChangeProductName = (event) => {
    setFormState((prevState) => {
      return {
        ...prevState,
        selectedProductValue: event.target.value,
        selectedProduct: null,
      };
    });

    if (event.target.value.length > 2) {
      debouncedFunction(event);
    }

    if (event.target.value === "") {
      setIsShowProductList(false);
    }
  };

  const handleSelectProductInList = (p) => {
    setFormState({
      ...formState,
      selectedProduct: p,
      selectedProductValue: p.name,
    });

    setIsShowProductList(false);
  };

  useEffect(() => {
    getUnverifiedProductCategories();
  }, []);

  return (
    <motion.div
      key={key}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      className="flex flex-col gap-5"
    >
      <div className="relative w-full">
        <Label className="">Ürün İsmi</Label>
        <Input
          className="mt-2"
          onChange={(e) => handleChangeProductName(e)}
          value={formState.selectedProductValue}
        />
        {isShowProductList && (
          <ul
            ref={ref}
            className="bg-white absolute top-24 left-0 z-20 w-full rounded-lg shadow-default"
          >
            {productListFromApi.map((product) => (
              <li
                className="py-3 px-4 hover:bg-[#F2F5F9] font-medium text-[#111729] text-sm cursor-pointer"
                onClick={() => handleSelectProductInList(product)}
                key={product.id}
              >
                {product.name}
              </li>
            ))}
          </ul>
        )}
      </div>
      {formState.selectedProduct ? (
        <>
          <div className="flex flex-col gap-2">
            <Label>Kategori</Label>
            <Input
              onChange={(e) => handleChangeProductName(e)}
              value={formState.selectedProduct?.unverifiedProductCategoryName}
            />
          </div>
          <div className="flex flex-col gap-2">
            <Label>Marka</Label>
            <Input
              onChange={(e) => handleChangeProductName(e)}
              value={formState.selectedProduct?.brand}
            />
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col gap-2">
            <Label>Kategori</Label>
            <Select
              onValueChange={(value) => {
                setFormState({
                  ...formState,
                  unverifiedProductCategoryId: value,
                });
              }}
            >
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {unverifiedProductCategories
                  .map((category) => ({
                    value: category.id,
                    label: category.name,
                  }))
                  .map((category, index) => {
                    return (
                      <SelectItem key={index} value={category.value}>
                        {category.label}
                      </SelectItem>
                    );
                  })}
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-col gap-2">
            <Label>Marka</Label>
            <Input
              onChange={(event) => {
                setFormState({ ...formState, brand: event.target.value });
              }}
              value={formState.brand}
            />
          </div>
        </>
      )}
      <div className="flex flex-col gap-2">
        <Label>Ürün Fiyatı</Label>
        <PriceInputBox
          value={formState.productPrice}
          onChange={(event) => {
            setFormState({ ...formState, productPrice: event });
          }}
          onChangeUnit={(event) => {
            setFormState({ ...formState, priceUnit: event });
          }}
          valueUnit={formState.priceUnit}
        />
      </div>
      <div className="flex flex-col gap-2">
        <Label>Satın Alma Tarihi</Label>
        <Input
          type="tel"
          ref={withMask({ mask: "99/99/9999" })}
          onChange={(event) => {
            setFormState({ ...formState, purchaseDate: event.target.value });
          }}
          value={formState.purchaseDate}
        />
      </div>
      {/* <FileArea
        title="test"
        description="test2"
        onFileUpload={(res) => {
          setFormState({ ...formState, billFile: res });
        }}
      /> */}
      <div className="flex flex-col gap-2">
        <Label>Fatura</Label>
        <Input
          onChange={(event) => {
            setFormState({ ...formState, billFile: event.target.files[0] });
          }}
          type="file"
        />
      </div>
    </motion.div>
  );
};

export default Step1;
